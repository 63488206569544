var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"row justify-content-end"},[_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.refresh()}}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" Refresh ")])]),_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.$router.push({
        name:'matches-create'
      })}}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" Add matche ")])])]),_c('br'),_c('table',{staticClass:"table table-striped table-hover"},[_vm._m(0),_c('tbody',_vm._l((_vm.matches),function(matche){return _c('tr',{key:matche.id},[_c('th'),_c('th',[_vm._v(" "+_vm._s(matche.terrainFullName)+" ")]),_c('th',[_vm._v(" "+_vm._s(matche.fieldFullName)+" ")]),_c('th',[_vm._v(" "+_vm._s(matche.date)+" ")]),_c('th',[_c('button',{staticClass:"btn btn-warning",on:{"click":function($event){return _vm.$router.push({
              name: 'reservations-details',
              params:{
                reference:matche.booking_reference
              }
            })}}},[_vm._v(" Afficher Réservation ")])]),_c('th',[_vm._v(" "+_vm._s(matche.fullNameTeamA)+" ")]),_c('th',[_vm._v(" "+_vm._s(matche.fullNameTeamB)+" ")]),_c('th',[_vm._v(" "+_vm._s(matche.goalsTeamA)+" ")]),_c('th',[_vm._v(" "+_vm._s(matche.goalsTeamB)+" ")]),_c('th',[_vm._v(" "+_vm._s(matche.startTime)+" ")]),_c('th',[_vm._v(" "+_vm._s(matche.endTime)+" ")]),_c('th',[(matche.is_active)?_c('button',{staticClass:"btn btn-success",on:{"click":function($event){return _vm.toggleMatcheStatus(matche.reference,false,matche.terrain_reference)}}},[_vm._v(" En jouant ")]):_c('button',{staticClass:"btn btn-danger",on:{"click":function($event){return _vm.toggleMatcheStatus(matche.reference,true,matche.terrain_reference)}}},[_vm._v(" En attente ")])]),_c('th',[_vm._v(" "+_vm._s(matche.remark)+" ")]),_c('th',[_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.$router.push(
              {
                name: 'matches-edit',
                params: {
                  reference:matche.reference
              }
              })}}},[_vm._v(" Editer ")]),_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.remove(matche.reference)}}},[_vm._v(" Annuler ")])])])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th'),_c('th',[_vm._v(" Terrain ")]),_c('th',[_vm._v(" Client ")]),_c('th',[_vm._v(" Date ")]),_c('th',[_vm._v(" Réservation ")]),_c('th',[_vm._v("Equipe A ")]),_c('th',[_vm._v(" Equipe B ")]),_c('th',[_vm._v("Buts A")]),_c('th',[_vm._v("Buts B")]),_c('th',[_vm._v("Time tart")]),_c('th',[_vm._v("Time end")]),_c('th',[_vm._v("Statut")]),_c('th',[_vm._v("Remarque ")]),_c('th')])])
}]

export { render, staticRenderFns }