<template>
  <div class="">
    <div class="row justify-content-end">
      <div class="col-auto">
        <button @click="refresh()" class="btn btn-primary">
          <i class="fa fa-plus"></i> Refresh
        </button>
      </div>

      <div class="col-auto">
        <button @click="$router.push({
          name:'matches-create'
        })" class="btn btn-primary">
          <i class="fa fa-plus"></i> Add matche
        </button>
      </div>
    </div>
    <br>
    <table class="table table-striped table-hover">
      <thead>
        <tr>
            
            <th></th>
            <th> Terrain </th>
            <th> Client </th>
            <th> Date </th>
            <th> Réservation </th>
            <th>Equipe A </th>
            <th> Equipe B </th>
            <th>Buts A</th>
            <th>Buts B</th>
            <th>Time tart</th>
            <th>Time end</th>
            <th>Statut</th>
            <th>Remarque  </th>
            <th></th>
          </tr>
      </thead>
      <tbody>
          <tr v-for="matche in matches" :key="matche.id">
            <th></th>
            <th>
                {{ matche.terrainFullName }}
            </th>

            <th>
                {{ matche.fieldFullName }}
            </th>

            <th>
                {{ matche.date }}
            </th>
            <th>
              <button @click="$router.push({
                name: 'reservations-details',
                params:{
                  reference:matche.booking_reference
                }
              })" class="btn btn-warning">
                Afficher Réservation
              </button>
            </th>
            <th>
              {{ matche.fullNameTeamA}}
            </th>
            <th>
              {{ matche.fullNameTeamB }}
            </th>
            <th>
              {{ matche.goalsTeamA }} 
            </th>
            <th>
              {{ matche.goalsTeamB }} 
            </th>
            <th>
              {{ matche.startTime }} 
            </th>
            <th>
              {{ matche.endTime }} 
            </th>
            <th>
              <!-- {{ matche.is_active	 }}  -->
              <button v-if="matche.is_active"
                @click="toggleMatcheStatus(matche.reference,false,matche.terrain_reference)" class="btn btn-success">
                En jouant
              </button>
              <button v-else @click="toggleMatcheStatus(matche.reference,true,matche.terrain_reference)" class="btn btn-danger">
                En attente
              </button>
            </th>
            <th>
              {{ matche.remark }} 
            </th>
            <th>
              <button @click="$router.push(
                {
                  name: 'matches-edit',
                  params: {
                    reference:matche.reference
                }
                })" class="btn ">

                Editer
              </button>
              <button @click="remove(matche.reference)" class="btn ">

                Annuler
              </button>
            </th>
            
          </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
    };
  },
  async mounted() {
    await this.$store.dispatch("matche/getAll");
    
  },
  computed: {
    ...mapGetters('matche', {
      matches: 'getMatches',
    }),
  },
  methods: {
    
    async refresh() {
      await this.$store.dispatch("matche/getAll");
    },

    async remove(reference) {
      await this.$store.dispatch("matche/destroy",reference);
    },

    async toggleMatcheStatus(reference,status,terrain_reference) {
      await this.$store.dispatch("matche/toggleMatcheStatus", {
        reference,
        status,
        terrain_reference
      });
    },
  }
};
</script>

<style scoped>
.bg-success {
  background: #78ff5d !important;
}
</style>
